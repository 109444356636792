import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => sessionStorage.getItem(key),
        setItem: (key, value) => sessionStorage.setItem(key, value),
        removeItem: key => sessionStorage.removeItem(key)
      }
    })
  ],
  state: {
    userInfo: {},
    assessmentId: null,
    invitedCampaignId: undefined,
    campaignId: null,
    token: null,
    userId: null,
    proctoringModelsLoaded: false,
    tabId: null
  },
  getters: {
    isAuthenticated: state => !!state.token,
    token: state => state.token,
    userId: state => state.userId,
    userInfo: state => state.userInfo,
    isModelLoaded: state => state.proctoringModelsLoaded,
    tabId: state => state.tabId
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    CLEAR_TOKEN(state) {
      state.token = null;
    },
    SET_USER_ID(state, userId) {
      state.userId = userId;
    },
    CLEAR_USER_ID(state) {
      state.userId = null;
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo
    },
    SET_PROCTORING_MODELS_LOADED(state, flag){
      state.proctoringModelsLoaded = flag
    },
    SET_TAB_ID(state, tabId) { // Mutation for setting tab identifier
      state.tabId = tabId;
    },
    CLEAR_TAB_ID(state) { // Mutation for clearing tab identifier
      state.tabId = null;
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit('SET_TOKEN', token);
    },
    clearToken({ commit }) {
      commit('CLEAR_TOKEN');
    },
    setUserId({ commit }, userId) {
      commit('SET_USER_ID', userId);
    },
    clearUserId({ commit }) {
      commit('CLEAR_USER_ID');
    },
    setUserInfo({ commit }, userInfo) {
      commit('SET_USER_INFO', userInfo);
    },
    setProctoringModelsLoaded({commit}, flag){
      commit('SET_PROCTORING_MODELS_LOADED', flag)
    },
    setTabId({ commit }, tabId) { // Action for setting tab identifier
      commit('SET_TAB_ID', tabId);
    },
    clearTabId({ commit }) { // Action for clearing tab identifier
      commit('CLEAR_TAB_ID');
    }
  },
  modules: {
  },
});
