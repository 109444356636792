import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Mixpanel from 'mixpanel-browser';
import * as VueGoogleMaps from "vue2-google-maps";
import VueCountryCode from "vue-country-code-select";
import keycloakService from "./services/KeycloakService";
// import { onAuthenticatedCallback } from "./services/AuthCallbacks";


Vue.use(VueCountryCode);

Mixpanel.init('33a0e3f1a5d1db6ce0f8191bef125853', {
  debug: false
});

Vue.prototype.$FCM_TOKEN = process.env.VUE_APP_FCM_TOKEN;

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
    libraries: "places"
  }
});

Vue.mixin({
  beforeCreate() {
    this.$mixpanel = Mixpanel;
  }
});

function onAuthenticatedCallback() {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
}

Vue.prototype.$onAuthenticatedCallback = onAuthenticatedCallback;

function isUserTypeTeacher() {
  const hash = window.location.hash;
  const params = new URLSearchParams(hash.substr(hash.indexOf('?') + 1));
  return params.get('userType') === 'Teacher';
}

const isTeacher = isUserTypeTeacher();

if (isTeacher) {
  keycloakService.initKeycloak(onAuthenticatedCallback);
} else {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
}
