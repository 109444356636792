import Keycloak from 'keycloak-js';
import store from '../store';
// import { onAuthenticatedCallback } from "./services/AuthCallbacks";
let initOptions = {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    onLoad: 'check-sso',
    KeycloakResponseType: 'code',
}

const kc = new Keycloak(initOptions);

const initKeycloak = (onAuthenticatedCallback) => {
    kc.init({
        onLoad: initOptions.onLoad,
      }).then((auth) => {
        if (!auth) {
            kc.login();
          } 
          else 
          {
            onAuthenticatedCallback();
            // store.dispatch('setToken', kc.token);
          }
      }, () => {
        console.log("Authentication Failure");
        onAuthenticatedCallback();
      });
  };



const doLogin = kc.login;

const doLogout = () => {
    // Specify the URI to redirect to after logout
    const postLogoutRedirectUri = process.env.VUE_APP_FRONTEND_URL+"?userType=Teacher&apn=ai.knoggles.ssvgos";
    kc.logout({ redirectUri: postLogoutRedirectUri });
};

const getToken = () => kc.token;

const getUserInfo = (userProperty) => kc.tokenParsed?.[userProperty] || null;

const getIsAuthenticated = () => kc.authenticated;

const refreshAccessToken = (sec) => kc.updateToken(sec);

const keycloakService = {
    initKeycloak,
    doLogin,
    doLogout,
    getToken,
    getUserInfo,
    getIsAuthenticated,
    refreshAccessToken
}

export default keycloakService;
